
export const isActive = ( { isCurrent }) => {
	
  return isCurrent ? { className: "kn-active" } : {}
}

export const isPartiallyActive = ( { isPartiallyCurrent}) => {
  
  return isPartiallyCurrent ? { className: "kn-active" } : {}
}

export default isActive;